<div class="container py-2" id="activity">
    <div class="h-100 p-5 bg-white border rounded-3 shadow">
        <h2 class="text-primary-color">Nasza firma wykonuje:</h2>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 pt-4">
            <ng-container *ngFor="let activity of activities">
                <app-activity [activityText]="activity.activityText"></app-activity>
            </ng-container>
        </div>
    </div>
</div>
