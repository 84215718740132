<div class="h-100 p-5 bg-white border rounded-3 shadow">
    <h2 class="display-6 fw-bold text-primary-color">Kontakt</h2>
    <div class="pt-2">
        <div class="row">
            <div class="col-md-5">
                <h4 class="fw-bold">P.W. "ANMAR"</h4>
                <h5>Andrzej Derenda</h5>
                <h6>ul. Łososiowa 9</h6>
                <h6>89-200 Zamość</h6>
                <h6>NIP: 554-101-41-05</h6>
            </div>
            <div class="col-md-6 pt-2 pt-md-0">
                <h4><a class="link" href="mailto:kontakt@derenda.app"><i
                    class="fas fa-envelope text-primary-color"></i>
                    <a class="mx-2 link text-primary-color fw-bold" href="mailto:mailto:kontakt@derenda.app">
                        kontakt&#64;derenda.app</a></a></h4>
                <h4 class="mt-3"><a class="link" href="tel:795796172"><i class="fas fa-phone text-primary-color"></i>
                    <a class="mx-2 link text-primary-color fw-bold" href="tel:795796172"> 795 796 172</a></a></h4>
            </div>
        </div>
    </div>
</div>
