import {Component} from '@angular/core';
import {Activity} from "../../../shared/models/activity";

@Component({
    selector: 'app-activities',
    templateUrl: './activities.component.html',
    styleUrl: './activities.component.scss'
})
export class ActivitiesComponent {

    public activities: Activity[] = [
        {
            activityText: 'przyłącza wodne'
        },
        {
            activityText: 'przyłącza kanalizacyjne'
        },
        {
            activityText: 'przyłącza gazowe'
        },
        {
            activityText: 'sieci wodociągowe'
        },
        {
            activityText: 'sieci kanalizacyjne'
        },
        {
            activityText: 'wymiana instalacji wodno kanalizacyjnej w budynkach wielorodzinnych (piony)'
        },
        {
            activityText: 'wymiana instalacji podposadzkowej'
        },
        {
            activityText: 'wymiana instalacji centralnego ogrzewania'
        },
        {
            activityText: 'udrażnianie pionów kanalizacyjnych'
        },
        {
            activityText: 'ogrzewanie podłogowe'
        },
        {
            activityText: 'instalacje w domkach jednorodzinnych'
        },
        {
            activityText: 'obsługa wod-kan budynków wielorodzinnych'
        },
    ];
}
