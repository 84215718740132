<div class="d-flex contact-link-box flex-wrap justify-content-md-end justify-content-center">
    <h6 class="display-none"><a class="text-decoration-none text-primary-color" href="mailto:kontakt@derenda.app"><i
        class="fas fa-envelope"></i>
        <a class="text-decoration-none fw-bold text-primary-color" href="mailto:kontakt@derenda.app">
            kontakt&#64;derenda.app</a></a></h6>
    <h6 class="px-2 display-none">|</h6>
    <h6 class="font-height"><a class="text-decoration-none text-primary-color" href="tel:795796172"><i
        class="fas fa-phone"></i>
        <a class="text-decoration-none fw-bold text-primary-color" href="tel:795796172"> 795 796 172</a></a></h6>
</div>
