<div class="h-100 p-5 bg-white border rounded-3 shadow">
    <h3 class="fw-bold text-primary-color">Godziny pracy biura:</h3>
    <div class="pt-4">
        <h5>Pon - Piątek: <b class="text-primary-color">8:00 - 16:00</b></h5>
        <h5 class="pt-2">Sobota: <b class="text-primary-color">10:00 - 14:00</b></h5>
    </div>
    <div class="pt-4">
        <h3 class="fw-bold text-primary-color">Serwis: 24h</h3>
    </div>
</div>
